import * as React from 'react';
import InPlaceMessage from 'styleguide/components/InPlaceMessage/InPlaceMessage';
import { ErrorMessage } from 'styleguide/components/forms';

export interface Props {
  helperText?: string;
  errorClass?: string;
  inPlaceError?: boolean;
  invalid?: boolean;
  error?: string;
  touched?: boolean;
}

const AbstractInputMessage = ({ helperText, errorClass, inPlaceError, touched, error, invalid }: Props) => (
  <>
    {inPlaceError && !invalid && <InPlaceMessage messageType="info" touched message={helperText} />}
    {inPlaceError && invalid ? (
      <InPlaceMessage messageType="error" touched={touched} message={error} className={errorClass} />
    ) : (
      touched && error && <ErrorMessage className={errorClass} message={error} />
    )}
  </>
);

export default AbstractInputMessage;
