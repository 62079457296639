export const flatten = (obj: object, parentKey?: string): object => {
  let result = {};

  if (obj === null || obj === undefined) {
    return null;
  }

  Object.keys(obj).forEach(key => {
    const value = obj[key];
    const derivedKey = parentKey ? `${parentKey}.${key}` : key;
    if (typeof value === 'object') {
      result = { ...result, ...flatten(value, derivedKey) };
    } else {
      result[derivedKey] = value;
    }
  });

  return result;
};
