import * as React from 'react';

interface Props {
  children: React.ReactNode;
}

const DividedSection = ({ ...props }: Props) => (
  <div className="ml-4 flex-grow basis-0 first:ml-0">{props.children}</div>
);

export default DividedSection;
