import * as React from 'react';
import cn from 'classnames';
import { FaExclamationTriangle } from '@react-icons/all-files/fa/FaExclamationTriangle';

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  message?: string;
}

const ErrorMessage = ({ message, ...props }: Props) => (
  <div className={cn('font-hvBold text-xs text-red', props.className)}>
    {!!message && (
      <div className="mt-1 flex">
        <FaExclamationTriangle className="-mt-0.5 ml-1 mr-1 h-4 w-4 transform text-red transition duration-150" />
        <div className="flex items-end">{message}</div>
      </div>
    )}
  </div>
);

export default ErrorMessage;
