import * as React from 'react';

interface Props {
  id?: string;
  name?: string;
  value?: string | number;
  children: React.ReactNode;
}

const Divided = ({ value, id, name, children }: Props) => (
  <div>
    <input value={value} id={id} name={name} type="hidden" />
    <div className="flex">{children}</div>
  </div>
);

export default Divided;
