import * as validateCard from 'card-validator';

export const getCreditCardProcessorByNumber = (ccNumber: string): string => {
  if (!ccNumber) {
    return null;
  }

  const validation = validateCard.number(ccNumber);

  if (validation.isValid) {
    return validation.card.type;
  }
  return null; // unknown type
};
