import * as React from 'react';

interface Props {
  value?: string | number;
  disabled?: boolean;
  children: React.ReactNode;
}

const SelectOption = (props: Props) => <option {...props} />;

export default SelectOption;
