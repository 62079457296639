import * as React from 'react';

interface Props {
  children?: string;
}

const SelectDefaultOption = ({ children = '…' }: Props) => (
  <option value="" disabled hidden>
    {children}
  </option>
);

export default SelectDefaultOption;
